import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { CreditsConnectorProps } from "./container";
import "./styles.scss";
import {
  TravelCreditDetailsCard,
  useDeviceTypes,
  ActionLink,
  CurrencyFormatters,
  IconName,
  AnnualTravelCreditInfoBanner,
  getRestrictedATCText,
  BannerSeverity,
  NotificationBanner,
  AnnualTravelCreditInfoModal,
  getATCProductName,
  CREDIT_HOW_DESCRIPTION,
  CREDIT_HOW_TITLE,
  CREDIT_STACKING_ADDITIONAL_DESCRIPTION,
} from "halifax";
import { ClientContext } from "../../../../App";
import {
  CREDIT_BALANCE_APPLICABILITY_TEXT,
  CREDIT_BALANCE_DESCRIPTION,
  CREDIT_BALANCE_TEXT,
  CREDIT_DETAILS_DESCRIPTION,
  CREDIT_DETAILS_TITLE,
  READ_TERMS_AND_CONDITIONS,
  VIEW_CREDIT_HISTORY_DESCRIPTION,
  VIEW_ACTIVITY_CTA,
  CREDIT_HISTORY_MODAL_SUBHEADER,
  CREDIT_HISTORY_MODAL_LIST_HEADER,
  CREDIT_HISTORY_BALANCE_TEXT,
} from "../../textConstants";
import { PATH_TRAVEL_CREDITS } from "../../../../utils/paths";
import { TravelCreditBalanceCard } from "../TravelCreditBalanceCard";
import {
  CorpSessionInfo,
  StatementCreditDetail,
  StatementCreditDetailStatus,
  VIEWED_CREDIT_BENEFITS_MODAL,
} from "redmond";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { config } from "../../../../api/config";

export interface ICreditsProps
  extends CreditsConnectorProps,
    RouteComponentProps {
  isTravelCreditHistoryExperiment?: boolean;
  isCreditAndOfferStackingExperimentV1?: boolean;
  showAnnualCreditBanner?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
}

const TRAVEL_CREDIT_TERMS_AND_CONDITIONS_LINK = () => {
  return (
    <ActionLink
      className="travel-wallet-terms-and-conditions"
      onClick={() => window.open(PATH_TRAVEL_CREDITS, "_blank")?.focus()}
      content={READ_TERMS_AND_CONDITIONS}
    />
  );
};

export const Credits = ({
  credit,
  creditBreakdown,
  rewardsAccounts,
  creditTransactionHistory,
  isTravelCreditHistoryExperiment,
  isCreditAndOfferStackingExperimentV1,
  showAnnualCreditBanner,
  isAnnualTravelCreditsExperiment,
}: ICreditsProps) => {
  const { sessionInfo } = React.useContext(ClientContext);
  const firstName = sessionInfo?.userInfo?.firstName || "";
  const { matchesMobile } = useDeviceTypes();
  const [atcInfoModalOpen, setAtcInfoModalOpen] =
    React.useState<boolean>(false);

  const isNonFinancialUser =
    (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "NFU";

  const restrictedATCText = getRestrictedATCText(
    rewardsAccounts,
    isAnnualTravelCreditsExperiment ?? false
  );

  const expiringATCText = React.useMemo(() => {
    if (!isAnnualTravelCreditsExperiment) return undefined;

    const expiringATC = creditBreakdown?.find(
      (creditDetail) =>
        creditDetail.CreditDetail === "Statement" &&
        creditDetail.status.Status !== StatementCreditDetailStatus.Empty &&
        dayjs().isBefore(creditDetail.expiresOn) &&
        dayjs(creditDetail.expiresOn).diff(dayjs(), "days") < 60
    ) as StatementCreditDetail | undefined;

    if (!expiringATC) return undefined;

    return (
      <Typography className="atc-expiration-banner-text">
        {getATCProductName(expiringATC, rewardsAccounts)}...
        {expiringATC.last4} annual travel credit expires{" "}
        <strong>{dayjs(expiringATC.expiresOn).format("MMM D, YYYY")}</strong>
      </Typography>
    );
  }, [creditBreakdown, rewardsAccounts, isAnnualTravelCreditsExperiment]);

  const hasVentureX = creditBreakdown.some(
    (creditDetail) =>
      creditDetail.CreditDetail === "Statement" &&
      creditDetail.productType === "VentureX" &&
      creditDetail.status.Status !== StatementCreditDetailStatus.Empty
  );

  const hasVentureXBusiness = creditBreakdown.some(
    (creditDetail) =>
      creditDetail.CreditDetail === "Statement" &&
      creditDetail.productType === "VentureXBusiness" &&
      creditDetail.status.Status !== StatementCreditDetailStatus.Empty
  );

  const renderDesktop = () => {
    return (
      <Box className="credits-container">
        <TravelCreditDetailsCard
          isMobile={matchesMobile}
          title={CREDIT_DETAILS_TITLE}
          description={CREDIT_DETAILS_DESCRIPTION(config.TENANT)}
          rewardsAccounts={rewardsAccounts}
          cardContent={{
            balanceCardProps: {
              description: CREDIT_BALANCE_DESCRIPTION(firstName),
              balanceText: CREDIT_BALANCE_TEXT(credit?.amount),
              applicabilityText: CREDIT_BALANCE_APPLICABILITY_TEXT,
              rewardsAccounts,
              credit,
              creditBreakdown,
            },
            accordionContents: [
              {
                title: CREDIT_HOW_TITLE,
                body: (
                  <Box className="credits-how">
                    <Typography className="credits-how-description">
                      {CREDIT_HOW_DESCRIPTION(
                        config.TENANT,
                        isNonFinancialUser
                      )}
                    </Typography>
                    {isCreditAndOfferStackingExperimentV1 && (
                      <Typography className="credit-stacking-description">
                        {CREDIT_STACKING_ADDITIONAL_DESCRIPTION(
                          config.TENANT,
                          isNonFinancialUser
                        )}
                      </Typography>
                    )}
                  </Box>
                ),
              },
            ],
            terms: TRAVEL_CREDIT_TERMS_AND_CONDITIONS_LINK(),
            showCreditHistory:
              isTravelCreditHistoryExperiment &&
              !!creditTransactionHistory.length,
            travelCreditHistoryProps:
              isTravelCreditHistoryExperiment &&
              !!creditTransactionHistory.length
                ? {
                    modalContentProps: {
                      header: CREDIT_BALANCE_DESCRIPTION(firstName),
                      balanceText: CREDIT_HISTORY_BALANCE_TEXT(
                        CurrencyFormatters.getSymbol(credit?.amount.currency),
                        CurrencyFormatters.get(credit?.amount.currency).format(
                          (credit?.amount?.amount &&
                            credit?.amount?.amount * -1) ||
                            0
                        )
                      ),
                      subHeader: CREDIT_HISTORY_MODAL_SUBHEADER,
                      creditListHeader: CREDIT_HISTORY_MODAL_LIST_HEADER,
                    },
                    icon: IconName.PiggyBankCircleOutline,
                    viewCreditHistoryDescription:
                      VIEW_CREDIT_HISTORY_DESCRIPTION,
                    viewHistoryCTAText: VIEW_ACTIVITY_CTA,
                    creditTransactionsHistory: creditTransactionHistory,
                  }
                : undefined,
          }}
          firstName={firstName}
          showAnnualCreditBanner={showAnnualCreditBanner}
          restrictedATCText={restrictedATCText}
          isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
        />
      </Box>
    );
  };

  const renderMobile = () => {
    return (
      <Box className="mobile-credits-container">
        {!restrictedATCText && expiringATCText && (
          <NotificationBanner
            content={expiringATCText}
            severity={BannerSeverity.WARNING}
            className="atc-expiration-banner"
          />
        )}
        {showAnnualCreditBanner && (
          <>
            <AnnualTravelCreditInfoBanner
              variant="small"
              rewardsAccounts={rewardsAccounts}
              creditBreakdown={creditBreakdown}
              firstName={firstName}
              onLearnMoreClick={() => {
                trackEvent({
                  eventName: VIEWED_CREDIT_BENEFITS_MODAL,
                  properties: {},
                });
                setAtcInfoModalOpen(true);
              }}
              hasCreditTransactionHistory={!!creditTransactionHistory.length}
            />
            <AnnualTravelCreditInfoBanner
              variant="small"
              rewardsAccounts={rewardsAccounts}
              creditBreakdown={creditBreakdown}
              firstName={firstName}
              cardType="Venture"
              hasCreditTransactionHistory={!!creditTransactionHistory.length}
            />
            <AnnualTravelCreditInfoModal
              hasVentureX={hasVentureX}
              hasVentureXBusiness={hasVentureXBusiness}
              open={atcInfoModalOpen}
              setOpen={setAtcInfoModalOpen}
              isMobile
            />
          </>
        )}
        {!!restrictedATCText && (
          <NotificationBanner
            content={restrictedATCText}
            severity={BannerSeverity.NOTICE}
            className="restricted-atc-banner"
          />
        )}
        <TravelCreditBalanceCard
          isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
          isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
        />

        <Box className="credits-how">
          <Typography className="credits-how-title" variant="h6">
            {CREDIT_HOW_TITLE}
          </Typography>
          <Typography className="credits-how-description">
            {CREDIT_HOW_DESCRIPTION(config.TENANT, isNonFinancialUser)}
          </Typography>
          {isCreditAndOfferStackingExperimentV1 && (
            <Typography className="credit-stacking-description">
              {CREDIT_STACKING_ADDITIONAL_DESCRIPTION(
                config.TENANT,
                isNonFinancialUser
              )}
            </Typography>
          )}
          <ActionLink
            className="credits-terms-and-conditions"
            onClick={() => window.open(PATH_TRAVEL_CREDITS, "_blank")?.focus()}
            content={READ_TERMS_AND_CONDITIONS}
          />
        </Box>
      </Box>
    );
  };

  return <>{matchesMobile ? renderMobile() : renderDesktop()}</>;
};
